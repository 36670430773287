import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './App.css';
import Entry from './Entry.js';
import NavBar from './NavBar.js';


function App() {

  const [entries, setEntries] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {

    const fetchData = async () => {

      const res = await fetch('https://www.swimmersparadise.com:4001/serverData');
      const resJson = await res.json();

      setEntries(resJson);
    }

    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(interval);

  }, []);

  useEffect(() => {
    //console.log(searchValue);
  }, [searchValue]);




  const displayEntries = (isPopulated) => {
    let componentArr = [];
    let hasAtLeastOne = false;
    entries.forEach((server) => {
      componentArr.push(<Entry data={server} requestedPopulatedOnly={isPopulated} searchValue={searchValue}/>);
      if (server.clients > 0) hasAtLeastOne = true;
    })

    if (isPopulated && !hasAtLeastOne) return <p>No Servers Found</p>
    return componentArr;
  }


  return (
    <div className="app">
      <NavBar />
      <div className='search'>
        <div className='search-icon'>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
          </svg>
        </div>
        <input 
          className='search-bar' 
          type="text" 
          placeholder='Search for a server'
          onChange={(e) => setSearchValue(e.target.value)}>
        </input>
      </div>
      <div className='content'>
        <div>
          <h2 className='section-heading'>Populated</h2>
          <div className='entry-container'>{displayEntries(1) || <p>no</p>}</div>
        </div>
        <div>
          <h2 className='section-heading'>All Servers</h2>
          <div className='entry-container'>{displayEntries()}</div>
        </div>
      </div>
    </div>
  );
}

export default App;
