import { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import Timer from './Timer.js';


function NavBar(props) {

  useEffect(() => {

  }, []);

  return (
  <div className='navbar'>
    <div className='top-image'></div>
    <div className='org-name'>Swimmer's Paradise</div>
    <Timer />
  </div>
  );
}


export default NavBar;
