import { useEffect, useLayoutEffect, useState } from 'react';
import './App.css';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';


function Timer(props) {

  useEffect(() => {

  }, []);

  return (
    <div className='timer'>
      <CountdownCircleTimer
        isPlaying
        duration={60}
        colors='#F7B801'
        size='26'
        strokeWidth='2'
        trailColor='rgba(255, 255, 255, 0.02)'
        onComplete={() => {
          // do your stuff here
          return { shouldRepeat: true } // repeat animation in 1.5 seconds
        }}
      >
      </CountdownCircleTimer>
      <span className='timer-help'>Time until next refresh.</span>
    </div>
  );
}


export default Timer;
