import { useEffect, useState } from 'react';
import './App.css';


function Entry(props) {

  const [entryClass, setEntryClass] = useState('entry');
  let newServerTitle = '';

  try {
    let serverTitleArr = props.data.name.split('|');

    for (let i = 2; i < serverTitleArr.length; i++) {
      newServerTitle += serverTitleArr[i];
    }
  } catch {
    newServerTitle = props.data.name || 'Not Running';
  }

  const inviteLink = `https://acstuff.ru/s/q:race/online/join?ip=52.201.153.157&httpPort=${props.data.port}`;


  useEffect(() => {

    // hiding based on search
    newServerTitle.toLowerCase().includes(` ${props.searchValue.trim().toLowerCase()}`) || 
    newServerTitle == 'Not Running' || 
    props.searchValue == '' ? setEntryClass('entry') : setEntryClass('entry-hidden');
    
    // hiding based on population
    if (props.requestedPopulatedOnly) {
      props.data.clients > 0 ? setEntryClass('entry') : setEntryClass('entry-hidden');
    }
  }, [props.searchValue, props.data.clients]);

  return (
  <div className={entryClass}>
    <div className='entry-title'>{newServerTitle}</div>
    <div className='entry-section-2'>
      <div className='clients'>{props.data.clients.toString()}/{props.data.maxclients.toString()} {props.data.status.toString()}</div>
      <a className='join-link' href={inviteLink} target='_blank'>Join</a>
    </div>
  </div>
  );
}


export default Entry;